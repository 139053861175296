<article class="container">
  <section class="aside">
    <div class="welcome">
      <h1 class="welcome__title">{{data.title}}</h1>
      <h2 class="welcome__description"
          [innerHTML]="data.description | safePureHtml"></h2>
    </div>
    <ng-container *ngIf="bannerRef">
      <ng-container *ngTemplateOutlet="bannerRef"></ng-container>
    </ng-container>
  </section>
  <section class="main">
    <ifc-sign-in-form [form]="form"
                       [isLoginWithSocialsAllowed]="data.isLoginWithSocialsAllowed"
                       [isRegisterAllowed]="data.isRegisterAllowed">
    </ifc-sign-in-form>
  </section>
</article>
