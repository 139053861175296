import { NgModule } from "@angular/core";
import { ForgotPasswordDialogComponent } from "./components/forgot-password-dialog/forgot-password-dialog.component";
import { SignInFormComponent } from './components/sign-in-form/sign-in-form.component';
import { SignInComponent } from './sign-in.component';
import { UiModule } from "../../ui/ui.module";
import { SignInActionsInternalService } from "./actions/sign-in-actions.internal.service";
import { SignInActionsService } from "./actions/sign-in-actions.service";
import { SignInSocialsModule } from "./sign-in-socials.module";

@NgModule({
  imports: [UiModule, SignInSocialsModule],
  declarations: [
    ForgotPasswordDialogComponent,
    SignInFormComponent,
    SignInComponent,
  ],
  exports: [
    SignInComponent,
  ],
  providers: [
    SignInActionsInternalService,
    SignInActionsService,
  ],
})
export class SignInModule { }
