import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppSettings } from '../../../../src/app.settings';
import { UserAuthorizeRequest } from '../model/auth.model';
import {
  SsoToken,
  TokenInfoRequest,
  TokenInfoResponse,
} from '../model/token.model';
import { BrandingService } from 'common';

@Injectable()
export class AuthService {
  public readonly formUrlEncodedHeaders = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  authFromUrlRequest: UserAuthorizeRequest;
  public ssoToken$: Observable<SsoToken>;
  private ssoToken: BehaviorSubject<SsoToken>;
  private baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly settings: AppSettings,
    private readonly brandingService: BrandingService
  ) {
    this.baseUrl = this.settings.sso.url;
    this.ssoToken = new BehaviorSubject<SsoToken>(
      JSON.parse(localStorage.getItem('ssoToken') ?? '{}')
    );
    this.ssoToken$ = this.ssoToken.asObservable();
  }

  public authorizeCodeFlow(
    userAuthReq: UserAuthorizeRequest,
    isRedirect = true
  ): Observable<string> {
    const body = this.transformToWwwForm(userAuthReq);
    return this.http
      .post(`${this.baseUrl}/authorize`, body, { ...this.formUrlEncodedHeaders, observe: 'response', responseType: 'text' })
      .pipe(
        switchMap(res => {
          console.log('Success: ' + res);
          if (res.status === 200)
            window.location.replace(res.body);
          return of<string>();
        }),
        catchError((err) => {
          console.log('Failed: ' + JSON.stringify(err) + 'isRedirect=' + isRedirect);
          if (isRedirect) {
            return this.handleRedirect(err);
          } else {
            throw err;
          }
        })
      );
  }

  public isTokenActive(req: TokenInfoRequest): Observable<TokenInfoResponse> {
    return this.http
      .post<TokenInfoResponse>(
        `${this.baseUrl}/token-info`,
        this.transformToWwwForm(req),
        this.formUrlEncodedHeaders
      )
      .pipe(
        catchError((err) => {
          switch (err.status) {
            case 401:
            case 404: {
              return of({ active: false });
            }

            default: {
              return throwError(err);
            }
          }
        }),
        tap((res) => {
          if (res.active !== true) {
            this.setToken(undefined);
          }
        })
      );
  }

  public provideAuthRequest(ssoToken: string): Observable<UserAuthorizeRequest> {
    return this.brandingService.getBranding().pipe(map((companyBranding) => {
      const portalForBranding = this.settings.portals?.find(p => p.url == companyBranding.portalUrl);

      return {
        client_id: this.authFromUrlRequest.client_id ?? portalForBranding?.client_id,
        redirect_uri: this.authFromUrlRequest.redirect_uri ?? (companyBranding.portalUrl + portalForBranding.callbackUrl),
        response_type: this.authFromUrlRequest.response_type ?? 'code',
        scope: this.authFromUrlRequest.scope ?? 'openid offline_access',
        ssoToken: ssoToken,
        state: this.authFromUrlRequest.state,
      };
    }))

  }

  public setAuthRequestFromUrl(): void {
    this.authFromUrlRequest = this.renderAuthRequestFromUrl();
  }

  public setToken(token: string) {
    if (token) {
      const tokenObject: SsoToken = {
        ssoToken: token,
        ssoTokenObject: jwtDecode(token)
      };
      localStorage.setItem('ssoToken', JSON.stringify(tokenObject));
      this.ssoToken.next(tokenObject);
    } else {
      localStorage.removeItem('ssoToken');
      this.ssoToken.next(undefined);
    }
  }

  public transformToWwwForm(obj: object) {
    const str = [];
    for (const p in obj)
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    return str.join('&');
  }

  private renderAuthRequestFromUrl(): UserAuthorizeRequest {
    const urlParams = new URLSearchParams(window.location.search);
    const responseType = urlParams.get('response_type');
    const clientId = urlParams.get('client_id');
    const redirect_uri = urlParams.get('redirect_uri');
    const scope = urlParams.get('scope');
    const state = urlParams.get('state');
    return {
      client_id: clientId,
      redirect_uri,
      response_type: responseType,
      scope,
      state,
      ssoToken: undefined
    };
  }

  private handleRedirect(err: HttpErrorResponse): Observable<string> {
    if ((err.status === 404 || err.status === 200) && err.url.includes('code=')) {
      console.log('Redirection on error');
      window.location.replace(err.url);
    }
    console.log('End of handleRedirect');
    return of();
  }
}
