import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MfaFacade } from './mfa.facade';
import { MfaLoginService } from './services/mfa-login.service';
import { MfaResetPasswordService } from './services/mfa-reset-password.service';

@UntilDestroy()
@Component({
  selector: 'ifs-register-mfa',
  templateUrl: './mfa.component.html',
  providers: [
    MfaFacade,
    MfaLoginService,
    MfaResetPasswordService
  ],
})
export class MfaComponent implements OnInit {
  constructor(private facade: MfaFacade) {}

  public ngOnInit(): void {
    this.facade.initActions();
  }
}
