import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { SignInFormData } from "../sign-in.model";
import { getForgotPasswordClosedDialogData } from "../sign-in.data";
import { MatDialog } from "@angular/material/dialog";
import { DialogActionComponent } from "../../../ui/dialog/action/dialog-action.component";
import { noReplyEmail } from "../../../../SharedConstants";
import { BrandingService } from "../../../../core/branding/branding.service";

@Injectable()
export class SignInActionsInternalService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly brandingService: BrandingService,
  ) { }

  private signInByPasswordSubject: Subject<SignInFormData> = new Subject();
  signInByPassword$: Observable<SignInFormData> = this.signInByPasswordSubject.asObservable();

  private markSignInAsCompletedSubject: Subject<void> = new Subject();
  markSignInAsCompleted$: Observable<void> = this.markSignInAsCompletedSubject.asObservable();

  private signInByGoogleSubject: Subject<string> = new Subject();
  signInByGoogle$: Observable<string> = this.signInByGoogleSubject.asObservable();

  private signInByAppleSubject: Subject<string> = new Subject();
  signInByApple$: Observable<string> = this.signInByAppleSubject.asObservable();

  private forgotPasswordSubject: Subject<string> = new Subject();
  forgotPassword$: Observable<string> = this.forgotPasswordSubject.asObservable();

  private signUpSubject: Subject<void> = new Subject();
  signUp$: Observable<void> = this.signUpSubject.asObservable();

  signInByPassword(data: SignInFormData): void {
    this.signInByPasswordSubject.next(data);
  }

  markSignInAsCompleted(): void {
    this.markSignInAsCompletedSubject.next();
  }

  signInByGoogle(token: string): void {
    this.signInByGoogleSubject.next(token);
  }

  signInByApple(token: string): void {
    this.signInByAppleSubject.next(token);
  }

  forgotPassword(email: string): void {
    this.forgotPasswordSubject.next(email);
  }

  handleForgotPasswordSuccess(email: string): void {
    this.brandingService.getBranding().subscribe((branding) => {
      const data = getForgotPasswordClosedDialogData(email, branding?.noReplyEmail ?? noReplyEmail);
      DialogActionComponent.show(this.dialog, data);
    })
  }

  signUp(): void {
    this.signUpSubject.next();
  }
}
