import { NgModule } from '@angular/core';
import { signUpRoutes } from './sign-up.routes';
import { RouterModule } from '@angular/router';
import { SignUpComponent } from './component/sign-up/sign-up.component';
import { SharedModule } from '../../../shared/shared.module';
import { SignUpService } from './service/sign-up.service';
import { SignInSocialsModule } from 'common';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(signUpRoutes),
    SignInSocialsModule
  ],
  declarations: [SignUpComponent],
  providers: [SignUpService],
  exports: [RouterModule]
})
export class SignUpModule { }
