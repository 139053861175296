import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SignInFormData, SignInFormGroup } from '../../sign-in.model';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SignInActionsInternalService } from '../../actions/sign-in-actions.internal.service';

@UntilDestroy()
@Component({
  selector: 'ifc-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {
  @Input()
  form: FormGroup<SignInFormGroup>;

  @Input()
  isLoginWithSocialsAllowed?: boolean;

  @Input()
  isRegisterAllowed?: boolean;

  isSignInDisabled: boolean;

  constructor(
    private readonly dialog: MatDialog,
    private readonly actionsService: SignInActionsInternalService,
  ) { }

  ngOnInit(): void {
    this.actionsService.markSignInAsCompleted$.pipe(untilDestroyed(this)).subscribe(() => {
      this.isSignInDisabled = false;
    });
  }

  onSignInByPassword() {
    if (!this.form.valid) {
      return;
    }

    this.isSignInDisabled = true;
    this.actionsService.signInByPassword(this.form.value as SignInFormData);
  }

  onSignInByGoogle(token: string) {
    this.actionsService.signInByGoogle(token);
  }

  onSignInByApple(token: string) {
    this.actionsService.signInByApple(token);
  }

  onForgotPassword() {
    const dialog = this.dialog.open(ForgotPasswordDialogComponent);
    dialog.afterClosed().subscribe((email: string) => {
      if (email) {
        this.actionsService.forgotPassword(email);
      }
    });
  }

  onSignUp() {
    this.actionsService.signUp();
  }
}
