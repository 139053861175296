import { Routes } from '@angular/router';
import { AppBarComponent, AppBarContactComponent, AppFooterComponent, AppPageComponent } from 'common';
import { SignInComponent } from './sign-in.component';

export const signInRoutes: Routes = [
  {
    path: 'sign-in',
    component: AppPageComponent,
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: 'simple',
      },
      appBar: {
        logoSize: 'logo-s',
        stickyDisabled: true,
      },
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: '',
        component: SignInComponent,
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
    ],
  },
];
