<div #appleSignInButton
  id="appleid-signin"
  data-mode="center-align"
  [attr.data-type]="context"
  data-color="white"
  data-border="true"
  data-border-radius="50"
  data-height="40"
  data-width="375"
  data-logo-size="large">
</div>
<p class="label">If using Apple ID, make sure that Share My Email option is selected</p>
