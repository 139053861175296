import { Injectable, NgZone } from "@angular/core";
import { MessageService, SignInActionsService, SignInFormData } from "common";
import { EMPTY } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { SignInService } from "./service/sign-in.service";
import { ForgotPasswordService } from "../password-reset/service/forgot-password.service";
import { Params, Router } from "@angular/router";

@Injectable()
export class SignInFacade {
  constructor(
    private readonly messageService: MessageService,
    private readonly actionsService: SignInActionsService,
    private readonly signInService: SignInService,
    private readonly forgotPasswordService: ForgotPasswordService,
    private readonly ngZone: NgZone,
    private readonly router: Router,
  ) { }

  signInByPassword(data: SignInFormData): void {
    this.signInService.signInByPassword(data.email, data.password, data.rememberMe)
      .pipe(finalize(() => this.actionsService.markSignInAsCompleted()))
      .subscribe();
  }

  signInByGoogle(token: string): void {
    this.ngZone.run(() => {
      this.signInService
        .signInGoogle({ idToken: token })
        .subscribe();
    });
  }

  signInByApple(token: string): void {
    this.ngZone.run(() => {
      this.signInService
        .signInApple({ idToken: token })
        .subscribe();
    });
  }

  forgotPassword(email: string): void {
    this.forgotPasswordService.requestToken({ email })
      .pipe(catchError(() => this.forgotPasswordError()))
      .subscribe(() => this.actionsService.handleForgotPasswordSuccess(email));
  }

  signUp(queryParams: Params): void {
    this.router.navigate(['/sign-up'], { queryParams });
  }

  private forgotPasswordError() {
    this.messageService.error("Error occurred while trying to reset password.");
    return EMPTY;
  }
}
