import { ButtonColor, DialogActionData } from "../../ui/dialog/action/dialog-action-data.model";

export const defaultWelcomeTitle: string = "Welcome back!";
export const defaultWelcomeDescription: string = "We're delighted <br />to see you again";

export function getForgotPasswordClosedDialogData(email: string, noReplyEmail: string): DialogActionData {
  return {
    body:
      `We sent an email to ${email} with instructions on how to reset your password.<br /><br />
      If you can't find the email in your inbox, check your spam folder. The email should come from: ${noReplyEmail}`,
    title: "Forgot your password?",
    buttons: [
      { label: "OK", buttonColor: ButtonColor.Primary, action: true },
    ],
  }};
