import { Component, NgZone, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BrandingService,
  CompanyBranding,
  CompanyColorSchemaEnum,
  MessageService,
  MetaService,
} from 'common';
import { SignInService } from '../../../sign-in/service/sign-in.service';
import { SignUpResponseError, SingUpRequest } from '../../model/sign-up.model';
import { SignUpService } from '../../service/sign-up.service';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ifs-sign-up-form',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  form: UntypedFormGroup;
  loginType = '';
  branding: CompanyBranding;
  companyColorSchema = CompanyColorSchemaEnum;
  loading: boolean;
  queryParams: any;

  constructor(
    private signUpService: SignUpService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private zone: NgZone,
    private metaService: MetaService,
    private brandingService: BrandingService,
    private signInService: SignInService
  ) {
    this.loading = false;
    this.metaService.createCanonicalURL(
      `${window.location.protocol}//${window.location.hostname}/sign-up`
    );
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      otp: ['', Validators.required],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
        ]),
      ],
      confirmPassword: [
        '',
        Validators.compose([
          Validators.required,
          formPasswordConfirmationValidator,
        ]),
      ],
    });
  }

  ngOnInit() {
    this.initBrandingInfo();
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(this.setQueryParams.bind(this));
  }

  private initBrandingInfo(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.branding = branding;
      });
  }

  public googleSignInSuccess(idToken: any) {
    this.zone.run(() => {
      this.form.markAsUntouched();
      if (!this.form.controls.otp.valid) {
        this.form.controls.otp.markAsTouched();
        return;
      }
      this.loading = true;
      this.signUpService
        .signUpGoogle({ otp: this.form.value.otp, idToken })
        .pipe(
          untilDestroyed(this),
        )
        .subscribe({
          next: () => this.googleSignUpSuccess(idToken),
          error: this.signUpError.bind(this),
        });
    });
  }

  public appleSignInSuccess(idToken: any) {
    this.zone.run(() => {
      this.form.markAsUntouched();
      if (!this.form.controls.otp.valid) {
        this.form.controls.otp.markAsTouched();
        return;
      }
      this.loading = true;
      this.signUpService
        .signUpApple({ otp: this.form.value.otp, idToken })
        .pipe(
          untilDestroyed(this),
        )
        .subscribe({
          next: () => this.appleSignUpSuccess(idToken),
          error: this.signUpError.bind(this),
        });
    });
  }

  public submit() {
    if (!this.form.valid) return;

    this.loading = true;
    this.signUpService
      .signUp(this.form.value)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.signUpSuccess(this.form.value),
        error: this.signUpError.bind(this),
      });
  }

  public navigateToSignIn() {
    this.router.navigate(['/sign-in'], { queryParams: this.queryParams });
  }

  private signUpError(response: any) {
    this.loading = false;

    const signUpError = response.error as SignUpResponseError
    const currentCode = signUpError?.errorCodes?.[0];
    if (currentCode) {
      this.renderErrorCode(currentCode);
    } else {
      this.messageService.error(response);
    }
  }

  private signUpSuccess(request: SingUpRequest) {
    this.signInService
      .signInByPassword(request.email, request.password, false)
      .pipe(
        untilDestroyed(this),
        tap(() => this.loading = false),
      )
      .subscribe();
  }

  private googleSignUpSuccess(idToken: string): void {
    this.signInService
      .signInGoogle({ idToken })
      .pipe(
        untilDestroyed(this),
        tap(() => this.loading = false),
      )
      .subscribe();
  }

  private appleSignUpSuccess(idToken: string): void {
    this.signInService
      .signInApple({ idToken })
      .pipe(
        untilDestroyed(this),
        tap(() => this.loading = false),
      )
      .subscribe();
  }

  private setQueryParams(queryParams: any) {
    this.queryParams = queryParams;
    this.form.patchValue(queryParams);
  }

  private renderErrorCode(currentCode: string) {
    switch (currentCode) {
      case 'email-mismatch': {
        this.messageService.error('There is a problem with your email or registration code.');
        break;
      }
      case 'acc-status': {
        this.messageService.error('Your registration is not suitable for approving.');
        break;
      }
    }
  }
}

function formPasswordConfirmationValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.parent) return;
  const passwordControl = control.parent.get('password');
  if (passwordControl.valid && control.value !== passwordControl.value)
    return { notMatch: 'Your password and confirmation must match.' };
  return null;
}
