export interface LoginDataModel {
  email: string;
}

export interface SingInMfaRequest {
  mfa_token: string;
  mfa_code: string;
  trust_device?: boolean;
}

export interface SingInResponse {
  ssoToken: string;
}

export interface SingInErrorResponse {
  hasError: boolean;
  errorCode?: string;
}

export interface SingInRequest extends Partial<SingInMfaRequest> {
  grant_type: string;
  username?: string;
  password?: string;
  idToken?: unknown;
  tokenProvider?: TokenProvider;
}

export enum TokenProvider {
  Google = 'google',
  Apple = 'apple'
}
