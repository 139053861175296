import { Component, OnInit } from '@angular/core';
import { BrandingService, CompanyColorSchemaEnum } from 'common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ifs-ssl-info',
  templateUrl: './ssl-info.component.html',
  styleUrls: ['./ssl-info.component.scss']
})
export class SslInfoComponent implements OnInit {
  colorSchema$: Observable<CompanyColorSchemaEnum>;

  constructor(private brandingService: BrandingService) { }

  ngOnInit(): void {
    this.colorSchema$ = this.brandingService.getBranding()
      .pipe(map((branding) => branding.colorSchema as CompanyColorSchemaEnum));
  }
}
