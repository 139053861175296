<p class="title">Login</p>
<form class="form"
      [formGroup]="form"
      (ngSubmit)="onSignInByPassword()">
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput
           placeholder="Email"
           required
           formControlName="email"
           id="email"
           autocomplete="email" />
    <mat-error> Please enter a valid email address. </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input matInput
           type="password"
           placeholder="Password"
           required
           formControlName="password"
           autocomplete="current-password" />
    <mat-error> Please enter a password. </mat-error>
  </mat-form-field>
  <div class="form__options">
    <mat-checkbox color="primary"
                  name="rememberMe"
                  formControlName="rememberMe"
                  type="check">
      Remember me
    </mat-checkbox>
    <a class="form__forgot-password"
       (click)="onForgotPassword()">
      Forgot password?
    </a>
  </div>
  <button class="form__submit"
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="isSignInDisabled">
    Login
  </button>
  <div class="socials"
       *ngIf="isLoginWithSocialsAllowed">
    <p class="form__label">or</p>
    <ifc-google-sign-in-button context="signin_with"
                               (signInSuccess)="onSignInByGoogle($event)">
    </ifc-google-sign-in-button>
    <ifc-apple-sign-in-button context="sign-in"
                              (signInSuccess)="onSignInByApple($event)">
    </ifc-apple-sign-in-button>
  </div>
  <div class="register"
       *ngIf="isRegisterAllowed">
    Don't have an account? Click
    <a class="register__link"
       (click)="onSignUp()">here</a> to create one.
  </div>
</form>
<ifc-ssl-info [isLearnMoreVisible]="false"></ifc-ssl-info>
