import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { SslInfoComponent } from './component/ssl-info/ssl-info.component';

const sharedComponents = [
  SslInfoComponent,
]

@NgModule({
  declarations: [
    ...sharedComponents
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonModule,
    ...sharedComponents
  ]
})
export class SharedModule { }
