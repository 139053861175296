<ifc-app-scroll-container>
  <div id="main" fxLayout="column" fxLayoutAlign="center center">

    <form (ngSubmit)="submit()" [formGroup]="form" [ngClass.xl]="'form-field-lg'" [ngClass.lg]="'form-field-md'"
      [ngClass.lt-lg]="'form-field-lg'" [ngClass.xs]="'form-field-xs'">
      <mat-form-field appearance="outline">
        <mat-label>Current password</mat-label>
        <input matInput type="password" placeholder="Enter your current password" autocomplete="current-password" required
          formControlName="currentPassword">
        <mat-error>
          Current password is required.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>New password</mat-label>
        <input matInput type="password" placeholder="Enter your new password" autocomplete="new-password" required
          formControlName="newPassword">
        <mat-error>
          <span style="margin-bottom:12px;"> Minimum of 8 characters, 1 uppercase letter, 1 lowercase letter, and 1
            number.</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline"
        [ngClass.lt-xl]="{'layout padding-top-md': form.get('newPassword').hasError('pattern') || (form.get('newPassword').hasError('required') && form.get('newPassword').touched) }">
        <mat-label>New password confirmation</mat-label>
        <input matInput type="password" placeholder="Confirm your new password" autocomplete="new-password" required formControlName="confirmPassword">
        <mat-error>
          Your password and confirmation must match
        </mat-error>
      </mat-form-field>
      <div class="flex row end-center">
        <button mat-button class="layout margin" (click)="back()" type="button">Cancel</button>
        <button mat-raised-button color="primary" class="layout margin margin-right-0" type="submit"
          [disabled]="!form.valid">Save</button>
      </div>
    </form>

  </div>
</ifc-app-scroll-container>
