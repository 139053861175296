import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ForgotPasswordService } from '../../service/forgot-password.service';

@UntilDestroy()
@Component({
  selector: 'ifs-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss']
})
export class PasswordResetFormComponent implements OnInit {
  token: string;
  loading: boolean

  form = this.formBuilder.group({
    newPassword: ['', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/)])],
    confirmPassword: ['', Validators.required]
  }, { validator: formPasswordConfirmationValidator('newPassword', 'confirmPassword') });

  constructor(
    private route: ActivatedRoute,
    private forgotPasswordService: ForgotPasswordService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  public ngOnInit() {
    this.loading = false;
    this.route.queryParams.pipe(
      untilDestroyed(this)
    )
    .subscribe(params => {
      this.token = params['token'];
    });
  }

  public submit() {
    if (!this.form.valid) return;
    this.loading = true;
    this.forgotPasswordService.submit({
      token: this.token,
      password: this.form.get('newPassword').value,
      confirmPassword: this.form.get('confirmPassword').value
    })
    .pipe(untilDestroyed(this))
      .subscribe(() => this.loading = false);
  }
}

function formPasswordConfirmationValidator(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
