import { NgModule } from "@angular/core";
import { UiModule } from "../../ui/ui.module";
import { AppleSignInButtonComponent } from "./components/apple-sign-in-button/apple-sign-in-button.component";
import { GoogleSignInButtonComponent } from "./components/google-sign-in-button/google-sign-in-button.component";

@NgModule({
  imports: [UiModule],
  declarations: [
    AppleSignInButtonComponent,
    GoogleSignInButtonComponent,
  ],
  exports: [
    AppleSignInButtonComponent,
    GoogleSignInButtonComponent,
  ],
})
export class SignInSocialsModule { }
