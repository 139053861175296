import { Component, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ScriptLoaderService } from './../../../../../core/script-loader/script-loader.service';
import { CompanyBranding } from './../../../../branding/branding.model';
import { ConfigService } from '../../../../config/config.service';
import { BrandingService } from './../../../../../core/branding/branding.service';

declare const AppleID: any;

@UntilDestroy()
@Component({
  selector: 'ifc-apple-sign-in-button',
  templateUrl: './apple-sign-in-button.component.html',
  styleUrls: ['./apple-sign-in-button.component.scss']
})
export class AppleSignInButtonComponent implements AfterViewInit {
  @ViewChild('appleSignInButton') appleSignInButton: ElementRef = new ElementRef({});

  @Output()
  signInSuccess = new EventEmitter<any>();

  @Input()
  context: 'sign-in' | 'sign-up';

  constructor(
    private readonly configService: ConfigService,
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly brandingService: BrandingService
  ) { }
  ngAfterViewInit(): void {
    document.addEventListener('AppleIDSignInOnSuccess', (event: any) => {
      this.signInSuccess.emit(event.detail.authorization.id_token)
    });

    this.loadScript().pipe(
      untilDestroyed(this),
      switchMap(() => this.brandingService.getBranding()))
      .subscribe({
        next: (branding) => {
          setTimeout(() => {
            this.initializeAppleSignInButton(branding);
          }, 0);
        },
        error: (error) => {
          console.error('Error loading Apple Library: ', error);
        }
      });
  }

  private loadScript() {
    return ConfigService.isConfigReady$.pipe(
      switchMap(() => this.scriptLoaderService.loadScript(this.configService.environment.apple.auth2.script_src, () => this.isAppleReady())))
  }

  private isAppleReady(): boolean {
    return (<any>window).AppleID && Object.keys(this.appleSignInButton.nativeElement).length > 0;
  }

  private initializeAppleSignInButton(companyBranding: CompanyBranding) {
    AppleID.auth.init({
      clientId: this.configService.environment.apple.auth2.client_id,
      scope: 'name email',
      redirectURI: this.configService.environment.apple.auth2.redirect_url ?? companyBranding.loginUrl,
      usePopup: true
    });
  }
}
