import { Routes } from "@angular/router";
import {
  AppBarCloseAction,
  AppBarComponent,
  AppBarTitleComponent,
  AppPageComponent
} from "common";
import { PasswordChangeFormComponent } from "./component/password-change-form/password-change-form.component";
import { SsoTokenGuard } from "../../../core/guard/sso-token.guard";

export const passwordChangeRoutes: Routes = [
  {
    path: "change-password",
    component: AppPageComponent,
    data: {
      appBar: {
        closeAction: AppBarCloseAction.MANUAL,
        logoHidden: true,
        stickyDisabled: true,
      },
    },
    canActivate: [SsoTokenGuard],
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "title",
            component: AppBarTitleComponent,
          },
        ],
      },
      {
        path: "",
        component: PasswordChangeFormComponent
      },
    ],
  },
];
