import { Routes } from '@angular/router';
import { AppBarComponent, AppBarContactComponent, AppFooterComponent, AppPageComponent } from 'common';
import { PasswordResetFormComponent } from './component/password-reset-form/password-reset-form.component';
import { PasswordResetDoneComponent } from './component/password-reset-done/password-reset-done.component';
import { PasswordTokenGuard } from './guard/password-token.guard';

export const passwordResetRoutes: Routes = [
  {
    path: 'password-reset',
    component: AppPageComponent,
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: 'simple'
      },
      appBar: {
        logoSize: 'logo-s',
        stickyDisabled: true
      }
    },
    canActivate: [PasswordTokenGuard],
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: PasswordResetFormComponent
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true
          }
        }
      }
    ]
  },
  {
    path: 'reset-done',
    component: AppPageComponent,
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: 'simple'
      },
      appBar: {
        logoSize: 'logo-s',
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: PasswordResetDoneComponent
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true
          }
        }
      }
    ]
  }
];
