import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { passwordResetRoutes } from './password-reset.routes';
import { ForgotPasswordDialogComponent } from './component/forgot-password-dialog/forgot-password-dialog.component';
import { ResetTokenSentDialogComponent } from './component/reset-token-sent-dialog/reset-token-sent-dialog.component';
import { ForgotPasswordService } from './service/forgot-password.service';
import { PasswordResetFormComponent } from './component/password-reset-form/password-reset-form.component';
import { PasswordResetDoneComponent } from './component/password-reset-done/password-reset-done.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(passwordResetRoutes)
  ],
  declarations: [
    ForgotPasswordDialogComponent,
    ResetTokenSentDialogComponent,
    PasswordResetFormComponent,
    PasswordResetDoneComponent
  ],
  providers: [ForgotPasswordService],
  exports: [RouterModule]
})
export class PasswordResetModule { }
