import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { ForgotPasswordService } from '../service/forgot-password.service';
import { ErrorsService } from 'common';
import { catchError, map } from 'rxjs/operators';
import { ErrorActionType } from 'common';

@Injectable({
  providedIn: 'root',
})
export class PasswordTokenGuard implements CanActivate {
  
  constructor(
    private router: Router,
    private forgotPasswordService: ForgotPasswordService,
    private errorsService: ErrorsService,
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | UrlTree {
    const email = next.queryParams['email'];
    const otp = next.queryParams['token'];

    if (!email || !otp) {
      return this.router.createUrlTree(['error']);
    }

    return this.forgotPasswordService.checkToken({ token: otp })
      .pipe(
        catchError(err => {
          if (err?.error === 'token-expired') {
            return of('popup');
          } else {
            return of('generic');
          }
        }),
        map(result => {
          switch(result) {
            case 'popup':{
              this.errorsService.showCustomErrorPage('Your password reset link has expired. Please request a new one.', 'Warning', ErrorActionType.Homepage);
              return false
            }
            case 'generic':{
              this.errorsService.showGeneralErrorPage(ErrorActionType.Homepage);
              return false
            }
            default:{
              return true;
            }
          }
        })
      )
  }
}
