<ifc-app-card [asideOrderLtLg]="-1" [asideHideXS]="true" [fxLayoutDirection]="'row'" fxLayout="row" fxLayout.gt-md="row"
  fxFlexAlign.lt-lg="center center" class="sign-in-panel">
  <p class="mat-headline">
    Create a new password
  </p>
  <form class="flex column" (ngSubmit)="submit()" [formGroup]="form">
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput type="password" placeholder="Enter your new password" autocomplete="new-password" required
        formControlName="newPassword">
      <mat-error>
        <span style="margin-bottom:12px;"> Minimum of 8 characters, 1 uppercase letter, 1 lowercase letter, and 1
          number.</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline"
      [ngClass.lt-xl]="{'layout padding-top-md': form.get('newPassword').hasError('pattern') || (form.get('newPassword').hasError('required') && form.get('newPassword').touched) }">
      <mat-label>Password confirmation</mat-label>
      <input matInput type="password" placeholder="Confirm your new password" autocomplete="new-password" required formControlName="confirmPassword">
      <mat-error>
        Your password and confirmation must match
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" class="layout margin-top-sm">
      <button type="button" mat-raised-button [routerLink]="['/sign-in']">Cancel</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="loading">Continue</button>
    </div>
  </form>

  <aside>
    <p class="mat-display-3 title">Welcome back!</p>
    <div class="user-aside-hr"></div>
    <p class="mat-display-1 aside">We're delighted <br />to see you again</p>
  </aside>

</ifc-app-card>

<ifs-ssl-info ></ifs-ssl-info>
