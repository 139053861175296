import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { PasswordChangeFormComponent } from './component/password-change-form/password-change-form.component';
import { passwordChangeRoutes } from './password-change.routes';
import { PasswordChangeService } from './service/password-change.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(passwordChangeRoutes)
  ],
  declarations: [
    PasswordChangeFormComponent
  ],
  providers: [PasswordChangeService],
  exports: [RouterModule]
})
export class PasswordChangeModule { }
