import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'projects/sso/src/app.settings';
import { PasswordChangeRequest } from '../model/password-change.model';
import { AuthService } from 'projects/sso/src/app/core/service/auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PasswordChangeService {

  constructor(private http: HttpClient, private settings: AppSettings, private authService: AuthService) { }

  public submit(req: PasswordChangeRequest): Observable<string> {
    if (req.ssoToken) {
      return this.changePassword(req);
    } else {
      return this.authService.ssoToken$.pipe(
        switchMap((ssoToken) => this.changePassword({ ...req, ssoToken: ssoToken.ssoToken }))
      );
    }
  }

  private changePassword(req: PasswordChangeRequest): Observable<string> {
    return this.http.post<string>(`${this.settings.sso.url}/change-password`, this.authService.transformToWwwForm(req), this.authService.formUrlEncodedHeaders);
  }
}
