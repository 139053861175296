import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SignInModule } from './sign-in/sign-in.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { PasswordChangeModule } from './password-change/password-change.module';

@NgModule({
  imports: [
    SharedModule,
    SignUpModule,
    SignInModule,
    PasswordChangeModule
  ],
})
export class UserModule {}
