import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { UserModule } from './features/user/user.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppLoaderInterceptor, ErrorsModule, sentryProviders } from 'common';
import { MfaModule } from './features/mfa/mfa.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    UserModule,
    MfaModule,
    SharedModule,
    ErrorsModule,
  ],
  providers: [
    ...sentryProviders, // TODO Only in sso we need to add sentryProviders locally, for some reason it doesn't import them from CoreModule (common), the same as we do in other projects - for further debugging
    { provide: HTTP_INTERCEPTORS, useClass: AppLoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
