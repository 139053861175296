import { NgModule } from '@angular/core';
import { CommonModule, MfaModule as MfaCommonModule } from 'common';
import { MfaRoutingModule } from './mfa-routing.module';
import { MfaComponent } from './mfa.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [MfaCommonModule, CommonModule, SharedModule, MfaRoutingModule],
  declarations: [MfaComponent],
})
export class MfaModule {}
