import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { RememberedLoginData, SignInComponentData, SignInComponentInputData } from "./sign-in.model";
import { defaultWelcomeDescription, defaultWelcomeTitle } from "./sign-in.data";
import { MetaService } from "../../meta/meta.service";

@Injectable()
export class SignInFacade {
  constructor(
    private readonly metaService: MetaService,
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  initData(inputData: SignInComponentInputData): SignInComponentData {
    this.metaService.createCanonicalURL(
      `${window.location.protocol}//${window.location.hostname}/sign-in`,
    );

    const rememberedLoginData: RememberedLoginData = JSON.parse(<string>localStorage.getItem("loginData") ?? null);
    return {
      ...inputData,
      title: inputData?.title ?? defaultWelcomeTitle,
      description: inputData?.description ?? defaultWelcomeDescription,
      rememberedLoginData };
  }

  initForm(emailValue: string = "") {
    const rememberValue = !!emailValue;
    return this.formBuilder.group({
      email: [emailValue, [Validators.required, Validators.email]],
      password: ["", Validators.required],
      rememberMe: [rememberValue],
    });
  }
}
