import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { SignInService } from './service/sign-in.service';
import { signInRoutes } from './sign-in.routes';
import { PasswordResetModule } from '../password-reset/password-reset.module';
import { MobileBannerComponent } from './component/mobile-banner/mobile-banner.component';
import { SignInComponent } from './sign-in.component';
import { SignInModule as CommonSignInModule } from 'common';

@NgModule({
  imports: [
    SharedModule,
    PasswordResetModule,
    RouterModule.forChild(signInRoutes),
    CommonSignInModule
  ],
  declarations: [SignInComponent, MobileBannerComponent],
  providers: [SignInService],
  exports: [RouterModule]
})
export class SignInModule { }
