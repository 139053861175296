import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BrandingService,
  CompanyBranding,
  MessageService,
  MetaService,
} from 'common';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AppSettings } from '../app.settings';
import { UserAuthorizeRequest } from './core/model/auth.model';
import { SsoToken, TokenInfoResponse } from './core/model/token.model';
import { AuthService } from './core/service/auth.service';
import { RedirectionService } from './core/service/redirection.service';

@UntilDestroy()
@Component({
  selector: 'ifs-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaService: MetaService,
    private brandingService: BrandingService,
    private messageService: MessageService,
    private authService: AuthService,
    private settings: AppSettings,
    private redirectionService: RedirectionService
  ) { }

  public ngOnInit(): void {
    this.authService.setAuthRequestFromUrl();

    this.brandingService.getBranding$()
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(data => {
        this.initBrandingTheme(data);
        this.setDOMElements(data);
      });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap((event: NavigationEnd) => {
        const urlTree = this.router.parseUrl(event.url);
        if (urlTree.root.children.primary === undefined) {
          return this.initToken();
        } else {
          return of(undefined);
        }
      }),
      take(1),
    ).subscribe();
  }

  private initToken(): Observable<SsoToken | string> {
    return this.authService.ssoToken$
      .pipe(
        map((ssoToken: SsoToken) => ssoToken?.ssoToken),
        switchMap((ssoToken: string) => {
          if (ssoToken) {
            return this.authService.isTokenActive({ ssoToken });
          } else {
            return of({ active: undefined });
          }
        }),
        switchMap((tokenInfo: TokenInfoResponse) =>
          this.handleTokenInfoResponse(tokenInfo)
        ),
      );
  }

  private handleTokenInfoResponse(tokenInfo: TokenInfoResponse): Observable<string | SsoToken> {
    if (tokenInfo.active === true) {
      const redirect = this.redirectionService.getInternalRedirect();
      return this.authService.ssoToken$.pipe(
        switchMap((ssoToken: SsoToken) =>
          this.authService.provideAuthRequest(ssoToken?.ssoToken)
        ),
        switchMap((authRequest: UserAuthorizeRequest) =>
          this.authService.authorizeCodeFlow(authRequest, !redirect)
        )
      );
    }
    if (tokenInfo.active === false) {
      this.messageService.error('Your session has expired.');
    }

    const snapshot = this.route.snapshot;
    const params = { ...snapshot.queryParams };
    this.router.navigate(['/sign-in'], { queryParams: params });

    return of(null);
  }

  private initBrandingTheme(brandingData: CompanyBranding) {
    if (brandingData?.colorSchema === 'levelesq') {
      this.renderer.setAttribute(
        this.document.body,
        'class',
        'theme-level-esq'
      );
    }
  }

  private setDOMElements(brandingData: CompanyBranding): void {
    if (this.router.url === '/sign-in')
      this.titleService.setTitle(
        `Business Financing | ${brandingData?.dba ?? 'Idea Financial'
        } Sign In`
      );
    else
      this.titleService.setTitle(
        `Business Financing | ${brandingData?.dba ?? 'Idea Financial'}`
      );

    this.metaService.createMetaTag(
      'description',
      `Welcome to your ${brandingData?.dba} account. You can access information about your business with us here.`
    );
    this.metaService.createAlternateURL(
      brandingData?.domains?.length
        ? brandingData?.domains[2]
        : this.settings.rootSite.url
    );
  }
}
