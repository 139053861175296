<h2 mat-dialog-title>Forgot your password?</h2>
<mat-dialog-content>
  <p>Type your email below so we can send you password reset instructions.</p>
  <form class="flex column"
        [formGroup]="form">
    <mat-form-field appearance="outline">
      <input id="email"
             matInput
             placeholder="Email"
             autocomplete="email"
             required
             formControlName="email" />
      <mat-error> Please enter a valid email address. </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="flex row end-center">
  <button mat-button
          mat-dialog-close>CANCEL</button>
  <button mat-button
          [mat-dialog-close]="form.value.email"
          [disabled]="!form.valid"
          color="primary">
    OK
  </button>
</mat-dialog-actions>
