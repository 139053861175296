import { NgModule } from '@angular/core';
import { AuthService } from './service/auth.service';
import { RedirectionService } from './service/redirection.service';
import { CoreModule as CommonCoreModule } from 'common';

@NgModule({
  imports: [CommonCoreModule],
  providers: [
    AuthService,
    RedirectionService,
  ],
})
export class CoreModule {}
