import { Injectable } from '@angular/core';

@Injectable()
export class RedirectionService {
  constructor() {
    this.clearInternalRedirect()
  }

  public clearInternalRedirect() {
    sessionStorage.removeItem('after-guard-redirect-to');
  }

  public setInternalRedirect(url: string) {
    sessionStorage.setItem('after-guard-redirect-to', url);
  }

  public getInternalRedirect(): string {
    return sessionStorage.getItem('after-guard-redirect-to');
  }
}
