import { Routes } from '@angular/router';
import {
  AppBarComponent,
  AppBarContactComponent,
  AppFooterComponent,
  AppPageComponent
} from 'common';
import { SignUpComponent } from './component/sign-up/sign-up.component';

export const signUpRoutes: Routes = [
  {
    path: 'sign-up',
    component: AppPageComponent,
    data: {
      appPage: {
        contentWithoutPadding: true,
        contentClass: 'simple'
      },
      appBar: {
        logoSize: 'logo-s',
        stickyDisabled: true
      }
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        component: SignUpComponent
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true
          }
        }
      }
    ]
  }
];
