<ifc-app-card [asideOrderLtLg]="-1" [asideHideXS]="true" [fxLayoutDirection]="'row'" fxLayout="row" fxLayout.gt-md="row"
  fxFlexAlign.lt-lg="center center" class="sign-in-panel">

  <p class="mat-headline"> Password reset successful </p>
  <p>Your password has been sucessfully reset. Please click "Login" button to proceed to login screen.</p>
  <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px" class="layout margin-top">
    <button type="button" mat-raised-button color="primary" [routerLink]="['/sign-in']">Login</button>
  </div>

  <aside>
    <p class="mat-display-3 title">Welcome back!</p>
    <div class="user-aside-hr"></div>
    <p class="mat-display-1 aside">We're delighted <br />to see you again</p>
  </aside>

</ifc-app-card>

<ifc-ssl-info fxHide.xs fxLayout="row" fxLayoutAlign="center strech"></ifc-ssl-info>
