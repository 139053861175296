import { Component } from '@angular/core';
import { appleStoreUrl, googlePlayUrl } from './mobile-banner.data';

@Component({
  selector: 'ifs-mobile-banner',
  templateUrl: './mobile-banner.component.html',
  styleUrls: ['./mobile-banner.component.scss']
})
export class MobileBannerComponent {
  onClickGooglePlay() {
    return window.open(googlePlayUrl, "_blank");
  }

  onClickAppleStore() {
    return window.open(appleStoreUrl, "_blank");
  }
}
