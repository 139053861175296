<div class="banner">
  <p class="banner__title">Get the mobile app</p>
  <p class="banner__description">Enjoy the flexibility of accessing your account from anywhere, at any time.</p>
  <div class="images">
    <img class="images__details"
         src="../../../../../assets/img/banner/banner-loan-details.png"
         alt="loan details">
    <div class="stores">
      <img class="stores__item"
           src="../../../../../assets/img/banner/google-play.svg"
           alt="google play"
           (click)="onClickGooglePlay()">
      <img class="stores__item"
           src="../../../../../assets/img/banner/apple-store.svg"
           alt="apple store"
           (click)="onClickAppleStore()">
    </div>
  </div>
</div>
