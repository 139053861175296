import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SignInFacade } from './sign-in.facade';
import { ActivatedRoute, Params } from '@angular/router';
import { BrandingService, CompanyBranding, SignInActionsService, SignInComponentInputData, SignInFormData } from 'common';

@UntilDestroy()
@Component({
  selector: 'ifs-sign-in',
  templateUrl: './sign-in.component.html',
  providers: [SignInFacade]
})
export class SignInComponent implements OnInit {
  data: SignInComponentInputData;
  queryParams: Params;
  displayBanner: boolean;

  constructor(
    private readonly facade: SignInFacade,
    private readonly actionsService: SignInActionsService,
    private readonly route: ActivatedRoute,
    private readonly brandingService: BrandingService,
  ) {
    this.data = {
      isLoginWithSocialsAllowed: true,
      isRegisterAllowed: true,
    }
  }

  public ngOnInit() {
    this.initQueryParams();
    this.initBranding();
    this.watchActions();
  }

  private initQueryParams() {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => this.queryParams = params);
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.displayBanner = branding.colorSchema === 'ideafinancial';
      });
  }

  private watchActions() {
    this.actionsService.signInByPassword$.pipe(untilDestroyed(this)).subscribe((data: SignInFormData) => this.facade.signInByPassword(data));
    this.actionsService.signInByGoogle$.pipe(untilDestroyed(this)).subscribe((token: string) => this.facade.signInByGoogle(token));
    this.actionsService.signInByApple$.pipe(untilDestroyed(this)).subscribe((token: string) => this.facade.signInByApple(token));
    this.actionsService.forgotPassword$.pipe(untilDestroyed(this)).subscribe((email: string) => this.facade.forgotPassword(email));
    this.actionsService.signUp$.pipe(untilDestroyed(this)).subscribe(() => this.facade.signUp(this.queryParams));
  }
}
