import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SignInComponentData, SignInComponentInputData } from './sign-in.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SignInFacade } from './sign-in.facade';
import { ActivatedRoute, Params } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'ifc-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [SignInFacade]
})
export class SignInComponent implements OnInit {
  @ContentChild("banner", { static: false, read: TemplateRef })
  bannerRef?: TemplateRef<unknown>;

  @Input()
  inputData: SignInComponentInputData;

  form: UntypedFormGroup;
  data: SignInComponentData;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly facade: SignInFacade,
  ) { }

  ngOnInit() {
    this.initData();
    this.watchQueryParams();
  }

  private initData() {
    this.data = this.facade.initData(this.inputData);
    this.form = this.facade.initForm(this.data.rememberedLoginData?.email);
  }

  private watchQueryParams() {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params: Params) => {
      if (!this.data?.rememberedLoginData?.email) {
        this.form.patchValue({
          email: params.email,
          remember: false,
        });
      }
    });
  }
}
