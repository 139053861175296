import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { MessageService } from 'common';
import { map, switchMap } from 'rxjs/operators';
import { SsoToken, TokenInfoResponse } from '../model/token.model';
import { AuthService } from '../service/auth.service';
import { RedirectionService } from '../service/redirection.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SsoTokenGuard implements CanActivate {
  private readonly urlTreeForSignIn = this.router.createUrlTree(['/sign-in']);

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router,
    private redirectionService: RedirectionService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UrlTree | boolean> {
    return this.authService.ssoToken$.pipe(
      map((ssoToken: SsoToken) => ssoToken?.ssoToken),
      switchMap((ssoToken: string) => {
        if (ssoToken) {
          return this.authService.isTokenActive({ ssoToken });
        } else {
          return of({ active: false });
        }
      }),
      map((tokenResponse: TokenInfoResponse) => {
        if (tokenResponse.active) {
          return true;
        } else {
          return this.handleRejection(state);
        }
      })
    );
  }

  private handleRejection(state: RouterStateSnapshot): UrlTree {
    this.redirectionService.setInternalRedirect(state.url);
    this.messageService.error('No valid session.');
    return this.urlTreeForSignIn;
  }
}
