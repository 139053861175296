export interface UrlModel {
  url: string;
}

export interface AuthUrlModel extends UrlModel {
  client_id: string;
  callbackUrl: string;
}

export class AppSettings {
  public rootSite: UrlModel;
  public sso: UrlModel;
  public portals: AuthUrlModel[];
  public companies: UrlModel;
  public mfa: UrlModel;
  public oauth: UrlModel;
  public google: {
    auth2: {
        client_id: string;
        cookiepolicy: string;
        script_src: string;
    };
  };
  public apple: {
    auth2: {
        client_id: string;
        redirect_url: string;
        script_src: string;
    };
  };
}
