import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'projects/sso/src/app.settings';
import { SingUpExternalProviderRequest, SingUpRequest } from '../model/sign-up.model';

@Injectable()
export class SignUpService {
  private baseUrl;

  constructor(private http: HttpClient, private settings: AppSettings) {
    this.baseUrl = this.settings.sso.url;
  }

  public signUp(req: SingUpRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/register/otp/internal`, req);
  }

  public signUpGoogle(req: SingUpExternalProviderRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/register/google`, req);
  }
  
  public signUpApple(req: SingUpExternalProviderRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/register/apple`, req);
  }
}
