import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InitMfaData, MessageService, MfaApiResponse, MfaConfig, MfaContextData, MfaContextType, MfaErrorCodeType, MfaErrorType, MfaService } from 'common';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppSettings } from '../../../../../app.settings';
import { AuthService } from '../../../../core/service/auth.service';
import { SingInExtras } from '../../../mfa/model/mfa.model';
import { SingInErrorResponse, SingInMfaRequest, SingInRequest, SingInResponse, TokenProvider } from '../model/customer.model';
import { LoginRequest } from 'projects/sso/src/app/core/model/auth.model';
import { SsoToken } from 'projects/sso/src/app/core/model/token.model';
import { RedirectionService } from 'projects/sso/src/app/core/service/redirection.service';

@Injectable()
export class SignInService {
  private baseUrl;

  constructor(
    private http: HttpClient,
    private settings: AppSettings,
    private authService: AuthService,
    private mfaService: MfaService,
    private router: Router,
    private messageService: MessageService,
    private redirectionService: RedirectionService
  ) {
    this.baseUrl = this.settings.sso.url;
  }

  public signInByPassword(
    username: string,
    password: string,
    rememberMe: boolean
  ): Observable<SingInResponse | SingInErrorResponse> {
    const request = {
      grant_type: 'password',
      username,
      password,
    };
    const extras: SingInExtras = {
      username,
      rememberMe,
    };
    return this.signInBase(request, extras);
  }

  public signInByMfa(
    request: SingInMfaRequest,
    extras: SingInExtras
  ): Observable<SingInResponse | SingInErrorResponse> {
    const requestBody = {
      grant_type: 'mfa_otp',
      mfa_token: request.mfa_token,
      mfa_code: request.mfa_code,
      trust_device: request.trust_device,
    };
    return this.signInBase(requestBody, extras);
  }

  public codeFlow(): Observable<string> {
    return this.authService.ssoToken$.pipe(
      map((ssoToken: SsoToken) => ssoToken?.ssoToken),
      switchMap((ssoToken: string) => this.authService.provideAuthRequest(ssoToken)),
      switchMap(authRequest => this.authService.authorizeCodeFlow(authRequest))
    );
  }

  public signInGoogle(idToken: any) {
    const request = {
      grant_type: 'authorization_code',
      tokenProvider: TokenProvider.Google,
      idToken: idToken,
    };
    const extras: SingInExtras = {
      username: null,
      rememberMe: null,
    };
    return this.signInBase(request, extras);
  }

  public signInApple(idToken: any) {
    const request = {
      grant_type: 'authorization_code',
      tokenProvider: TokenProvider.Apple,
      idToken: idToken,
    };
    const extras: SingInExtras = {
      username: null,
      rememberMe: null,
    };
    return this.signInBase(request, extras);
  }

  public confirmPhoneAndSignIn(
    request: SingInMfaRequest,
    extras: SingInExtras
  ): Observable<SingInResponse | SingInErrorResponse> {
    return this.confirmPhoneAndSignInApiRequest(request).pipe(
      tap((data: SingInResponse) => this.handleSignInSuccess(data, extras)),
      catchError((error) => this.handleSignInError(error, extras))
    );
  }

  public forgotPassword(email: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/password/forgot`, {
      email: email,
    });
  }

  private signInBase(
    request: SingInRequest,
    extras: SingInExtras
  ): Observable<SingInResponse | SingInErrorResponse> {
    return this.signInApiRequest(request).pipe(
      tap((data: SingInResponse) => this.handleSignInSuccess(data, extras)),
      catchError((error) => this.handleSignInError(error, extras))
    );
  }

  private signInApiRequest(request: SingInRequest): Observable<SingInResponse> {
    if (request.grant_type === 'password') {
      return this.http.post<SingInResponse>(`${this.baseUrl}/login`, { email: request.username, password: request.password } as LoginRequest, { withCredentials: true });
    }

    if (request.grant_type === 'mfa_otp') {
      return this.http.post<SingInResponse>(`${this.baseUrl}/login/otp`, request, { withCredentials: true });
    }

    if (request.grant_type === 'authorization_code') {
      return this.http.post<SingInResponse>(`${this.baseUrl}/login/${request.tokenProvider}`, request.idToken, { withCredentials: true });
    }
  }

  private confirmPhoneAndSignInApiRequest(
    request: SingInMfaRequest
  ): Observable<SingInResponse> {
    return this.http.post<SingInResponse>(
      `${this.baseUrl}/api/phone-number/confirm`,
      null,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Ryno-Mfa-Token': request.mfa_token,
          'X-Ryno-Mfa-Code': request.mfa_code,
        },
      }
    );
  }

  private handleSignInSuccess(response: SingInResponse, extras: SingInExtras) {
    this.handleLocalLoginData(extras?.rememberMe, extras?.username);
    this.authService.setToken(response.ssoToken);

    const guardRedirection = this.redirectionService.getInternalRedirect();
    if (guardRedirection) {
      this.redirectionService.clearInternalRedirect();
      this.router.navigateByUrl(guardRedirection);
    } else {
      this.codeFlow().subscribe();
    }
  }

  private handleSignInError(
    response: any,
    extras: SingInExtras
  ): Observable<SingInErrorResponse> {
    if (response.status === 401) {
      this.messageService.error(
        'The email and password provided did not match our records. Double check this info and try again.'
      );
      return of({ hasError: true });
    }

    if (response.status === 400 && response?.error?.error === 'invalid_grant') {
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }

    if (response.status === 400) {
      this.messageService.error(response);
      return of({ hasError: true });
    }

    if (response.status === 403 && this.hasMfaError(response?.error?.error)) {
      return this.handleMfaErrors(response?.error, extras);
    }

    this.messageService.error('Error occurred while trying to log in.');
    return of({ hasError: true });
  }

  private hasMfaError(errorType: any) {
    return Object.values(MfaErrorType).includes(errorType);
  }

  private handleMfaErrors(mfaResponse: MfaApiResponse, extras: SingInExtras) {
    switch (mfaResponse.error) {
      case MfaErrorType.MfaRequired:
        return this.handleMfaRequiredError(mfaResponse, extras);
      case MfaErrorType.MfaCodeError:
        return this.handleMfaCodeError(mfaResponse);
      default:
        console.error(`Mfa error: ${mfaResponse.error}`);
        return of({ hasError: true });
    }
  }

  private handleMfaRequiredError(
    mfaResponse: MfaApiResponse,
    extras: SingInExtras
  ) {
    const contextData: MfaContextData = {
      username: extras.username,
      rememberMe: extras.rememberMe,
    };

    const config: MfaConfig = {
      canAddPhoneNumberIfMissing: true,
      canChangePhoneNumberIfUnverified: true,
      isTrustingDeviceAllowed: true,
    };

    const data: InitMfaData = {
      mfaContextType: MfaContextType.Login,
      mfaResponse,
      contextData,
      config,
    };

    this.mfaService.initMfa(data);
    return of({ hasError: true });
  }

  private handleMfaCodeError(mfaResponse: MfaApiResponse) {
    if (
      mfaResponse?.send_code_status?.error_result?.error ===
      MfaErrorCodeType.InvalidMfaCode
    ) {
      return of({ hasError: true, errorCode: MfaErrorCodeType.InvalidMfaCode });
    }
  }

  private handleLocalLoginData(remember: boolean, email: string) {
    if (remember) {
      localStorage.setItem('loginData', JSON.stringify({ email }));
    } else {
      localStorage.removeItem('loginData');
    }
  }
}
