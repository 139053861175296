import {
  Component,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigService } from '../../../../config/config.service';
import { ScriptLoaderService } from '../../../../../core/script-loader/script-loader.service';

@UntilDestroy()
@Component({
  selector: 'ifc-google-sign-in-button',
  templateUrl: './google-sign-in-button.component.html'
})
export class GoogleSignInButtonComponent implements AfterViewInit {
  @ViewChild('googleSignInButton') googleSignInButton: ElementRef = new ElementRef({});

  @Output()
  signInSuccess = new EventEmitter<any>();

  @Input()
  context: 'signin_with' | 'signup_with' = 'signin_with';

  private isInitialized = false;

  constructor(
    private readonly configService: ConfigService,
    private readonly scriptLoaderService: ScriptLoaderService
  ) { }

  ngAfterViewInit() {
    this.loadScript()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.initGoogleButton();
        },
        error: (error) => {
          console.error('Error loading Google Library: ', error);
        }
      });
  }

  private loadScript() {
    return ConfigService.isConfigReady$.pipe(
      switchMap(() => this.scriptLoaderService.loadScript(this.configService.environment.google.auth2.script_src, () => this.isGoogleReady())))
  }

  private isGoogleReady(): boolean {
    return (<any>window).google && (<any>window).google.accounts && Object.keys(this.googleSignInButton.nativeElement).length > 0;
  }

  private initGoogleButton() {
    this.renderGoogleButton();

    fromEvent(window, 'resize').pipe(
      untilDestroyed(this),
      debounceTime(200)
    ).subscribe(() => {
      this.renderGoogleButton();
    });
  }

  private renderGoogleButton() {
    this.initGoogleSignInBase();

    const container = document.getElementById('g_id_signin');
    if (container) {
      container.innerHTML = '';
    }

    setTimeout(() => {
      this.renderGoogleButtonBase(this.calculateButtonWidth())
    }, 0);
  }

  private initGoogleSignInBase() {
    if (!this.isInitialized) {
      const config = this.configService.environment.google.auth2;
      (<any>window).google.accounts.id.initialize({
        client_id: config.client_id,
        auto_select: false,
        auto_prompt: true,
        callback: (response) => this.signInSuccess.emit(response.credential)
      });
      this.isInitialized = true;
    }
  }

  private renderGoogleButtonBase(width: number) {
    (<any>window).google.accounts.id.renderButton(
      document.getElementById('g_id_signin'),
      { theme: 'outline', size: 'large', shape: 'pill', logo_alignment: 'center', text: this.context, width }
    );
  }

  private calculateButtonWidth() {
    const currentWidth = (<any>window).outerWidth;
    const minWidth = 280;
    const maxWidth = 400;
    const margins = 32;
    const threshold = maxWidth + margins;

    if (currentWidth > threshold) {
      return maxWidth;
    } else if (currentWidth - margins < minWidth) {
      return minWidth;
    } else {
      return currentWidth - margins
    }
  }
}
