import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppBarCloseActionService, AppBarTitleService, MessageService } from 'common';
import { PasswordChangeFormGroup, PasswordChangeFormValue, PasswordChangeRequest, QueryParamsPasswordChangeFormComponent } from '../../model/password-change.model';
import { PasswordChangeService } from '../../service/password-change.service';
import { HttpErrorResponse } from '@angular/common/http';

@UntilDestroy()
@Component({
  selector: 'ifs-password-change-form',
  templateUrl: './password-change-form.component.html',
  styleUrls: ['./password-change-form.component.scss'],
})
export class PasswordChangeFormComponent implements OnInit {
  params: QueryParamsPasswordChangeFormComponent;

  form = this.formBuilder.group(
    {
      currentPassword: ['', Validators.compose([Validators.required])],
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
        ]),
      ],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: formPasswordConfirmationValidator(
        'newPassword',
        'confirmPassword'
      ),
    }
  ) as PasswordChangeFormGroup;

  constructor(
    private route: ActivatedRoute,
    private passwordChangeService: PasswordChangeService,
    private formBuilder: UntypedFormBuilder,
    private appBarTitleService: AppBarTitleService,
    private appBarCloseService: AppBarCloseActionService,
    private messageService: MessageService
  ) {}

  public ngOnInit() {
    this.route.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(this.paramsInit.bind(this));

    this.appBarCloseService.closing$
      .pipe(untilDestroyed(this))
      .subscribe(this.handleClose.bind(this));

    this.appBarTitleService.title = 'Update your password';
  }

  public submit() {
    if (!this.form.valid) return;
    this.passwordChangeService
      .submit(this.formToRequest(this.form.value))
      .pipe(untilDestroyed(this))
      .subscribe(this.submitSuccess, this.submitError);
  }

  public back() {
    this.handleClose();
  }

  private paramsInit(params: QueryParamsPasswordChangeFormComponent) {
    this.params = params;
  }

  private handleClose() {
    if (this.params.redirectUrl) {
      window.location.href = this.params.redirectUrl;
    } else {
      window.history.back();
    }
  }

  private formToRequest(value: PasswordChangeFormValue): PasswordChangeRequest {
    return {
      oldPassword: value.currentPassword,
      newPassword: value.newPassword
    };
  }

  private submitError = (response: HttpErrorResponse) => {
    let message = '';

    switch (response.status) {
      case 200:
      case 201: {
        this.submitSuccess();
        return;
      }
      case 400: {
        message = response.error[0]?.description;
        break;
      }
      case 401: {
        message = 'Session expired. Please refresh the page and try again.';
        break;
      }
      default: {
        message = response.error
        ? response.error
        : 'Error ocurred while trying to change password';
        break;
      }
    }
    this.messageService.error(message);
  };

  private submitSuccess = () => {
    this.messageService.success('Your password has been changed.');
    this.handleClose();
  };
}

function formPasswordConfirmationValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
