export const noReplyEmail = "noreply@mail.ideafinancial.com";

export const defaultCalendarLinkUrl = "https://calendly.com/ideafinancial";

export const WIDE_SCREEN_WIDTH = 600;

export const FILE_SIZE_50_MB = 52428800;

export const DEFAULT_FILE_DIALOG_HEIGHT = 500;

export const DEFAULT_FILE_DIALOG_WIDTH = 640;

export const ZIP_VALIDATOR_PATTERN = /^\d{5}(-?\d{4})?$/;

export const PHONE_VALIDATOR_PATTERN = /^(\d{10})|(\(\d{3}\)\s\d{3}-\d{4})$/;
