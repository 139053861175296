import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SignInActionsInternalService } from "./sign-in-actions.internal.service";
import { SignInFormData } from "../sign-in.model";

@Injectable()
export class SignInActionsService {
  constructor(private readonly internalService: SignInActionsInternalService) {}

  get signInByPassword$(): Observable<SignInFormData> {
    return this.internalService.signInByPassword$;
  }

  markSignInAsCompleted(): void {
    this.internalService.markSignInAsCompleted();
  }

  get signInByGoogle$(): Observable<string> {
    return this.internalService.signInByGoogle$;
  }

  get signInByApple$(): Observable<string> {
    return this.internalService.signInByApple$;
  }

  get forgotPassword$(): Observable<string> {
    return this.internalService.forgotPassword$;
  }

  handleForgotPasswordSuccess(email: string): void {
    return this.internalService.handleForgotPasswordSuccess(email);
  }

  get signUp$(): Observable<void> {
    return this.internalService.signUp$;
  }
}
