import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ScriptLoaderService {
    loadScript(scriptSrc: string, isLoaded: () => boolean): Observable<void> {
        return new Observable((observer: Observer<void>) => {
            if (isLoaded()) {
                observer.next();
                observer.complete();
                return;
            }

            const script = document.createElement('script');
            script.src = scriptSrc;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                if (isLoaded()) {
                    observer.next();
                    observer.complete();
                } else {
                    observer.error(new Error('Script loaded incorrectly'));
                }
            };
            script.onerror = () => {
                observer.error(new Error('Failed to load script'));
            };
            document.head.appendChild(script);
        });
    }
}
